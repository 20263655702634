import { ArticleMedia } from 'designsystem/components/2-molecules/Article';
import { BackgroundContainerFeature } from 'features/Modules/BackgroundContainer';
import { useCentralStore } from 'store';
import { ImageFeature } from '../Image/ImageFeature';

const aspectRatioAsNumber = (aspectRatioString: '16:9' | '1:1' | '9:16') => {
	const values = aspectRatioString.split(':');
	return Number(values[1]) / Number(values[0]);
};

export const ImageContentFeature = ({ content, settings, noBackground = false, spacing = true }: Content.ImageContent) => {
	const { caption, picture } = content?.properties ?? {};
	const { fullImage = false, aspectRatio, width, customWidth, position, noTopMargin, hideImageOnSmallScreens } = settings?.properties ?? {};
	const { isMobile } = useCentralStore();
	const { image, altText } = picture[0]?.content?.properties ?? {};
	const { url, focalPoint } = image ?? {};

	return hideImageOnSmallScreens && isMobile ? null : (
		<BackgroundContainerFeature enableBackground={!noBackground} noTopMargin={noTopMargin}>
			<ArticleMedia
				caption={caption}
				image={
					<ImageFeature
						url={url}
						altText={altText}
						focalPoint={focalPoint}
						sizes="100vw"
						isCover={fullImage !== 'True'}
						hideImageOnSmallScreens={hideImageOnSmallScreens}
						aspectRatio={aspectRatio ? aspectRatioAsNumber(aspectRatio) : undefined}
					/>
				}
				width={width === 'Custom' || width === '' ? undefined : width}
				position={!position ? 'Left' : position}
				customWidth={customWidth}
				spacing={spacing}
			/>
		</BackgroundContainerFeature>
	);
};
